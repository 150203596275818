import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: _ctx.modalId,
    "modal-title": _ctx.title,
    "hide-cancel-button": _ctx.hideCancelButton,
    "cancel-button-text": _ctx.cancelButtonText,
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      _renderSlot(_ctx.$slots, "modal-content", {}, () => [
        _createVNode(_component_tm_stylized_text, {
          class: "body-text-regular-14 lh-20",
          "text-container": _ctx.textContainer,
          text: _ctx.text
        }, null, 8, ["text-container", "text"])
      ])
    ]),
    "modal-footer-button": _withCtx(({ hideModal }) => [
      _createVNode(_component_tm_button, {
        color: _ctx.btnColor,
        size: "large",
        "loading-with-content": _ctx.loadingStatus,
        disable: _ctx.loadingStatus,
        onClick: ($event: any) => (_ctx.handleClick(hideModal))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 2
      }, 1032, ["color", "loading-with-content", "disable", "onClick"])
    ]),
    _: 3
  }, 8, ["id", "modal-title", "hide-cancel-button", "cancel-button-text"]))
}