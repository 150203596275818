
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { ButtonColor, TextArray } from '@/definitions/shared/types'
import type { ModalNames } from '@/components/modals/types'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'

export default defineComponent({
  components: { TmStylizedText, TmButton, TmModal },
  props: {
    modalId: {
      type: String as PropType<ModalNames>,
      default: 'confirmation',
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
      required: true,
    },
    textContainer: {
      type: String,
      default: 'span',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    btnText: {
      type: String,
      required: true,
    },
    btnColor: {
      type: String as PropType<ButtonColor>,
      default: 'blue',
    },
    hideCancelButton: {
      type: Boolean,
    },
    clickEvent: {
      type: Function,
    },
    btnClickLoading: {
      type: Boolean,
    },
  },
  setup(props) {
    const loadingStatus = ref(false)
    const handleClick = (event: () => void) => {
      if (props.clickEvent) {
        props.clickEvent()
      }
      if (props.btnClickLoading) {
        loadingStatus.value = true
        setTimeout(() => {
          loadingStatus.value = false
          event()
        }, 2000)
      } else {
        event()
      }
    }

    return {
      handleClick,
      loadingStatus,
    }
  },
})
